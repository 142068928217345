export default {
  primary: "#ff0000", //
  alt: "#005baa", //
  dark: "#333742", //
  black: "#000000", //
  obsidian: "#040C0C", //
  slateDark: "#32393E", //
  slateMid: "#60676E", //
  slateLight: "#A7AEB5", //
  silver: "#E9EBEC", //
  silver20: "rgba(233, 235, 236, 0.2)", //
  snow: "#FFFFFF", //
  dirtySnow: "#FFFEFD", //
  lightestGray: "#F2F2F2",
  mediumGray: "#A7ABAA",
  grayCard: "#F5F7F8",
  grayCard04: "rgba(0, 22, 55, 0.04)", //
  grayDisabled: "rgba(118, 118, 118, 0.5)", //
  greyCheckbox: "#464646",
  greyLabel: "#B0B4BB", //
  divider: "rgba(0, 0, 0, 0.05)", //
  divider30: "rgba(0, 0, 0, 0.3)", //
  divider50: "rgba(0, 0, 0, 0.5)", //
  dividerHover: "rgba(0, 0, 0, 0.1)", //
  white05: "rgba(255, 255, 255, 0.05)", //
  error: "#F53D3D", //
  error05: "rgba(245, 61, 61, 0.05)", //
  error50: "rgba(245, 61, 61, 0.5)", //
  red: "#ff0000", //
  offWhite: "#FAF9F6", //
}
