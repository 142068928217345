export const formatCheckoutUrl = (checkoutUrl: string) => {
  return process.env.NEXT_PUBLIC_SHOPIFY_SHOP_DOMAIN &&
    process.env.NEXT_PUBLIC_SHOPIFY_SHOP_CUSTOM_DOMAIN
    ? checkoutUrl.replace(
        process.env.NEXT_PUBLIC_SHOPIFY_SHOP_DOMAIN,
        process.env.NEXT_PUBLIC_SHOPIFY_SHOP_CUSTOM_DOMAIN
      )
    : checkoutUrl
}

function getModule() {
  if (window.location.href.indexOf("/powersports") > -1) {
    return "/powersports"
  }

  return ""
}

export const getBlogUrl = (blogSlug: string): string =>
  `${getModule()}/blog/${blogSlug}`.toLowerCase()

export const getJobUrl = (jobSlug: string): string =>
  `${getModule()}/careers/${jobSlug}`.toLowerCase()

export const getGalleryUrl = (gallerySlug: string): string =>
  `${getModule()}/gallery/${gallerySlug}`.toLowerCase()

export const getAwardeeUrl = (awardeeSlug: string): string =>
  `${getModule()}/trim-awards/${awardeeSlug}`.toLowerCase()

export const getEventUrl = (eventSlug: string): string =>
  `${getModule()}/trim-awards/events/${eventSlug}`.toLowerCase()

export const getInstallationUrl = (installationSlug: string): string =>
  `${getModule()}/installation/${installationSlug}`.toLowerCase()

export const getChildProductUrl = (childSlug: string): string =>
  `${getModule()}/products/${childSlug}`.toLowerCase()

export const getProductUrl = (
  productSlug: string,
  make?: string,
  model?: string,
  year?: string,
  trim?: string
): string =>
  make && model && year
    ? `${getModule()}/products/${productSlug}/${make}/${model}/${year}${
        trim ? trim : ""
      }`.toLowerCase()
    : `${getModule()}/products/${productSlug}`.toLowerCase()

export const getProSeriesUrl = (series: string = "pro-series"): string =>
  `${getModule()}/${series}`

export const getProSeriesCategoryUrl = (
  categorySlug: string,
  series: string = "pro-series"
): string => `${getModule()}/${series}/${categorySlug}`.toLowerCase()

export const getProSeriesSubcategoryUrl = (
  categorySlug: string,
  subcategorySlug: string,
  series: string = "pro-series"
): string =>
  `${getModule()}/${series}/${categorySlug}/${subcategorySlug}`.toLowerCase()

export const getProSeriesProductStyleUrl = (
  categorySlug: string,
  subcategorySlug: string,
  productStyleSlug: string,
  series: string = "pro-series"
): string =>
  `${getModule()}/${series}/${categorySlug}/${subcategorySlug}/${productStyleSlug}`.toLowerCase()

export const getCollectionCUrl = (collectionSlug: string): string =>
  `${getModule()}/collections/${collectionSlug}`.toLowerCase()

export const getStoreUrl = (storeSlug: string): string =>
  `/store/${storeSlug}`.toLowerCase()

export const getCarsMakeModelYearUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string
): string =>
  `${getModule()}/cars/${makeSlug}/${modelSlug}/${year}`.toLowerCase()

export const getCarsMakeModelYearCategoryUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string,
  categorySlug: string
): string =>
  `${getModule()}/cars/${makeSlug}/${modelSlug}/${year}/${categorySlug}`.toLowerCase()

export const getCarsMakeModelYearTrimUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string,
  trimSlug: string
): string =>
  `${getModule()}/cars/${makeSlug}/${modelSlug}/${year}/${trimSlug}`.toLowerCase()

export const getCarsMakeModelYearTrimCategoryUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string,
  trimSlug: string,
  categorySlug: string
): string =>
  `${getModule()}/cars/${makeSlug}/${modelSlug}/${year}/${trimSlug}/${categorySlug}`.toLowerCase()

export const getCarsMakeModelYearCategoryFilterUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string,
  categorySlug: string,
  filter: string
): string =>
  `${getModule()}/cars/${makeSlug}/${modelSlug}/${year}/${categorySlug}/${filter}`.toLowerCase()

export const getCategoryUrl = (categoryUrl: string): string =>
  `/categories/${categoryUrl}`.toLowerCase()

export const getCategoryMakeUrl = (
  categoryUrl: string,
  makeSlug: string
): string =>
  `${getModule()}/categories/${categoryUrl}/${makeSlug}`.toLowerCase()

export const getCategoryMakeModelUrl = (
  categoryUrl: string,
  makeSlug: string,
  modelSlug: string
): string =>
  `${getModule()}/categories/${categoryUrl}/${makeSlug}/${modelSlug}`.toLowerCase()

export const getCategoryMakeModelYearUrl = (
  categoryUrl: string,
  makeSlug: string,
  modelSlug: string,
  year: string
): string =>
  `${getModule()}/categories/${categoryUrl}/${makeSlug}/${modelSlug}/${year}`.toLowerCase()

export const getSearchUrl = (query: string): string =>
  `${getModule()}/search/query=${query}`

export const getSearchBySkuUrl = (query: string): string =>
  `${getModule()}/search-by-sku/query=${query}`

export const getClearanceUrl = () => `${getModule()}/clearance`

export const getClearanceMakeModelYearUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string
): string =>
  `${getModule()}/clearance/${makeSlug}/${modelSlug}/${year}`.toLowerCase()

export const getClearanceMakeModelYearTrimUrl = (
  makeSlug: string,
  modelSlug: string,
  year: string,
  trimSlug: string
): string =>
  `${getModule()}/clearance/${makeSlug}/${modelSlug}/${year}/${trimSlug}`.toLowerCase()
